import * as React from 'react'
import { AnchorsOfForms } from '@constants/forms'
import { Loading } from '@shared/components/loading'
import { changeDocumentHash } from '@shared/pipes/dom'
import scrollToElement from '@shared/pipes/scroll'
import { emitGTMEvent } from '@shared/utils/helpers'
import { AnimateSharedLayout, motion, type MotionProps } from 'framer-motion'

import { Layout } from '../components/Layout'

import { ApplicationFormProps, StepKeys } from './types'
import { useFormController } from './useFormController'
import dynamic from 'next/dynamic'

interface ApplicationFormContextDto {
	formController: ReturnType<typeof useFormController>['formController']
	setOptionalDocsIds: React.Dispatch<React.SetStateAction<Record<string, boolean>>>
	optionalDocsIds: Record<string, boolean>
	hasLead: boolean
	setHasLead: (value: boolean) => void
	setHasConfirmed: (value: boolean) => void
	setHasBonused: (value: boolean) => void
	hasEsia: boolean
	currentStepNumber: number
	setFormCookie: (value: string) => void
	logout: () => void
}

const stepsComponents = {
	primary: dynamic(() => import('./steps/Primary').then(({ Primary }) => Primary), { ssr: false }),
	'esia-auth': dynamic(() => import('./steps/EsiaAuth').then(({ EsiaAuth }) => EsiaAuth), {
		ssr: false,
	}),
	confirm: dynamic(() => import('./steps/ConfirmOrder').then(({ ConfirmOrder }) => ConfirmOrder), {
		ssr: false,
	}),
	bonus: dynamic(() => import('./steps/Bonus').then(({ Bonus }) => Bonus), { ssr: false }),
	success: dynamic(() => import('./steps/Success').then(({ Success }) => Success), { ssr: false }),
} satisfies Record<StepKeys, any>

export const ApplicationFormContext = React.createContext<ApplicationFormContextDto>(null!)

export const ApplicationForm = (props: ApplicationFormProps) => {
	const {
		isLoading,
		currentStep,
		currentStepNumber,
		formController,
		hasLead,
		setHasLead,
		hasEsia,
		setHasConfirmed,
		setHasBonused,
		optionalDocsIds,
		setOptionalDocsIds,
		setFormCookie,
		logout,
	} = useFormController(!!props.isFullPage, props?.data?.leadProductType || 'REAL_ESTATE_LOAN')

	const Step = isLoading || !stepsComponents[currentStep] ? Loading : stepsComponents[currentStep]

	const contextValue: ApplicationFormContextDto = {
		formController,
		hasLead,
		setHasLead,
		optionalDocsIds,
		setOptionalDocsIds,
		setHasConfirmed,
		setHasBonused,
		hasEsia,
		currentStepNumber,
		setFormCookie,
		logout,
	}

	const motionProps: MotionProps = {
		initial: { opacity: 0 },
		animate: { opacity: 1 },
	}

	return (
		<div id={AnchorsOfForms.application}>
			<ApplicationFormContext.Provider value={contextValue}>
				<Layout>
					<AnimateSharedLayout>
						<motion.div {...motionProps} key={currentStep}>
							<React.Suspense>
								<Step
									{...props}
									formTitle={props.formTitle || 'Заявка на кредит'}
									processBarText={props.processBarText || 'Вероятность одобрения кредита'}
								/>
							</React.Suspense>
						</motion.div>
					</AnimateSharedLayout>
				</Layout>
			</ApplicationFormContext.Provider>
		</div>
	)
}

export const scrollToApplicationForm = () => {
	scrollToElement(`#${AnchorsOfForms.application}`, { time: 0 }, () => {
		changeDocumentHash(AnchorsOfForms.application)
		emitGTMEvent('basic-scroll')
	})
}
